<template>
  <v-card
    width="100%"
    class="mb-2"
  >
    <v-card-title class="justify-center">
      {{ title ? title : '-' }}
    </v-card-title>
    <v-card-text class="text-center pb-10 pt-5">
      <h1>
        {{ point ? point.toLocaleString() : 0 }}
      </h1>
    </v-card-text>
  </v-card>
</template>

<script>
  export default {
    name: 'DrawerPoint',
    props: {
      title: String,
      point: Number,
    },
  }
</script>

<style scoped>

</style>

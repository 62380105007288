<template>
  <div>
    <v-navigation-drawer
      v-model="userInfoDrawer"
      app
      class="overflow-visible"
      :class="{ 'black': $vuetify.theme.dark }"
      right
      width="325"
      static
      style="overflow: visible"
    >
      <v-row
        class="mx-0 flex-column fill-height"
      >
        <v-col>
          <v-list
            class="py-0"
            color="transparent"
            two-line
          >
            <template #default>
              <v-divider />
              <v-list-item>
                <div class="d-flex align-center" :style="{ width: '100%' }">
                  <v-avatar
                    color="green"
                    size="14"
                    class="mr-2"
                  />
                  <span class="text-h6">
                    {{ $store.state.user.user.id }}
                  </span>
                  <span class="ml-1">
                    (online)
                  </span>
                  <v-spacer />
                  <v-btn
                    icon
                    @click="logout"
                  >
                    <v-icon>
                      mdi-logout
                    </v-icon>
                  </v-btn>
                </div>
              </v-list-item>
              <v-divider />
              <template v-if="!$store.state.user.user.admin">
                <v-list-item>
                  <v-subheader>
                    사용자 정보 요약
                  </v-subheader>
                </v-list-item>
                <v-list-item class="flex-wrap">
                  <DrawerPoint title="활동포인트" :point="$store.state.user.user.activityPoints" />
                  <DrawerPoint title="적립포인트" :point="$store.state.user.user.feePoints" />
                  <DrawerPoint title="추천인 포인트" :point="$store.state.user.user.recommendPoints" />
                </v-list-item>
                <v-divider class="mt-5" />
                <v-list-item>
                  <v-subheader>
                    추가 사용자
                  </v-subheader>
                </v-list-item>
                <v-list-item v-for="alias in $store.state.user.user.aliases" :key="alias.idx" class="mb-2">
                  <v-sheet rounded width="100%" class="pa-5">
                    <v-icon class="mr-3">
                      mdi-account-plus
                    </v-icon>
                    {{ alias.username }} ({{ alias.id }})
                  </v-sheet>
                </v-list-item>
              </template>
              <template v-else>
                <v-list-item>
                  <v-subheader>
                    최근 신규상품 신청 (20건)
                  </v-subheader>
                </v-list-item>
                <v-list-item class="flex-wrap pl-0">
                  <div>
                    <v-timeline
                      align-top
                      small
                      dense
                      clipped
                    >
                      <v-timeline-item v-for="reg in registrations" :key="'drawer-reg-' + reg.idx" color="pink" small>
                        <v-card>
                          <v-card-text class="py-1">
                            <p class="text-caption mb-1">
                              {{ $parseDateMinute(reg.registerDate) }}
                            </p>
                            <p class="text-caption mb-1">
                              {{ reg.product.productName }}
                            </p>
                            <p class="text-caption mb-1">
                              {{ reg.product.company.companyName }} {{ reg.options ? reg.options.optionContent : '' }}
                            </p>
                            <p class="text-caption mb-0">
                              {{ reg.alias ? (reg.alias.username + ' ' + reg.alias.mobileIdx) : (reg.users.username + ' ' + reg.users.mobileIdx ) }}
                            </p>
                          </v-card-text>
                        </v-card>
                      </v-timeline-item>
                    </v-timeline>
                  </div>
                </v-list-item>
              </template>
            </template>
          </v-list>
        </v-col>
      </v-row>
    </v-navigation-drawer>
    <core-fab />
  </div>
</template>

<script>
  // Utilities
  import {
    mapMutations, mapState,
  } from 'vuex'
  import DrawerPoint from '@/components/points/DrawerPoint'

  export default {
    name: 'CoreUserInfo',
    components: {
      DrawerPoint,
      CoreFab: () => import('./Fab'),
    },
    data () {
      return {
        registrations: null,
      }
    },
    computed: {
      ...mapState('friends', [
        'drawer',
      ]),
      userInfoDrawer: {
        get () {
          return this.drawer
        },
        set (val) {
          this.setDrawer(val)
        },
      },
    },
    beforeMount () {
      this.getRecentRegistrations()
    },
    methods: {
      async getRecentRegistrations () {
        await this.$axios.get('/manage/product/recent').then(res => {
          if (res && res.data) {
            this.registrations = res.data
          }
        })
      },
      ...mapMutations('friends', ['setDrawer']),
      logout () {
        this.$store.dispatch('user/logout')
      },
      blockFriend () {
        //
      },
      menu (event) {
        //
      },
      removeFriend () {
        //
      },
      whisperFriend () {
        //
      },
    },
  }
</script>
